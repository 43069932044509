<template>
  <div v-show="isActive" @click="onCLick"><slot></slot></div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    name: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false
    };
  },

  computed: {
    href() {
      return "#" + this.name.toLowerCase().replace(/ /g, "-");
    }
  },

  mounted() {
    this.isActive = this.selected;
  },

  methods: {
    onCLick() {
      this.$emit("test");
    }
  }
};
</script>

<!--<style scoped></style>-->
