<template>
  <router-view />
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "assets/scss/global";
</style>
