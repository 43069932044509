var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showSettings)?_c('div',{staticClass:"group-settings-wrapper"},[_c('div',{staticClass:"header"},[_c('button',{staticClass:"close-btn",on:{"click":_vm.closeModal}},[_c('img',{attrs:{"src":"img/close-user-list.svg","alt":"close"}})]),_c('h5',[_vm._v("Nastavení skupiny")]),_c('div',{staticClass:"user-block"},[_c('label',{staticClass:"image-block"},[_c('img',{attrs:{"src":_vm.groupImage,"alt":"user-avatar"}}),_c('input',{attrs:{"type":"file"},on:{"change":_vm.onFileUpload}})]),_c('span',{staticClass:"user-name"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.groupName),expression:"groupName"}],attrs:{"type":"text"},domProps:{"value":(_vm.groupName)},on:{"keyup":function($event){return _vm.updateGroup(null)},"input":function($event){if($event.target.composing){ return; }_vm.groupName=$event.target.value}}})])])]),_c('div',{staticClass:"users-list"},[_vm._m(0),_c('ul',{staticClass:"ul-list",class:{ 'scroll-list': _vm.members.length > 3 }},_vm._l((_vm.members),function(member,index){return _c('li',{key:member.external_id},[_c('div',{staticClass:"left-side"},[_c('span',{staticClass:"user-image"},[_c('img',{attrs:{"src":member.photo,"alt":"user-avatar"}})]),_c('span',{staticClass:"username"},[_vm._v(_vm._s(member.name))])]),_c('div',{staticClass:"right-side"},[_c('DropdownList',{attrs:{"items":[
              {
                label: 'Odstranit uživatele ze skupiny',
                type: 'action',
                actionName: 'delete',
                id: member.external_id,
                index: index
              }
            ],"is-label":false,"top":'32px',"left":'-156px'},on:{"action":_vm.actionHandler}})],1)])}),0),_c('ul',{staticClass:"fixed-list"},[_c('li',[_c('div',{staticClass:"left-side"},[_c('span',{staticClass:"user-image"},[_c('img',{attrs:{"src":_vm.admin.photo,"alt":"user-avatar"}})]),_c('span',{staticClass:"username"},[_vm._v(_vm._s(_vm.admin.name))])]),_vm._m(1)]),_c('li',[_vm._m(2),_c('div',{staticClass:"right-side"},[_c('button',{staticClass:"plus-btn-image",on:{"click":_vm.addNewMember}},[_c('img',{staticClass:"plus-btn-image",attrs:{"src":"img/chevron-right.svg","alt":"link-icon"}})])])])])]),_c('div',{staticClass:"leave-block"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"parent-block",on:{"click":_vm.leaveGroup}},[_vm._m(3),_c('span',{staticClass:"username"},[_vm._v("Odejít ze skupiny")])])])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('h6',[_vm._v("Účastníci")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right-side"},[_c('span',[_vm._v("Admin")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left-side"},[_c('span',{staticClass:"user-image"},[_c('img',{attrs:{"src":"img/new-member.svg","alt":"user-avatar"}})]),_c('span',{staticClass:"username"},[_vm._v("Přidat další účastníky")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"user-image"},[_c('img',{attrs:{"src":"img/leave-group.svg","alt":"user-avatar"}})])}]

export { render, staticRenderFns }